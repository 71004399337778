<template>
    <div style="margin-top: 10px;">
        <h2>
            Notice to Policyholders: How We Protect Your Privacy
        </h2>
        <br />
        <p>
            Jewelers Mutual Insurance Company, SI respects the privacy of our customers and protects nonpublic personal
            information that you share with us. We do not sell our customer list to any vendors or distributors. This
            notice informs you, our valued policyholder, how we at Jewelers Mutual collect, use, and protect your
            nonpublic personal information.
        </p>
        <br />
        <p>
            <span class="subheading">Collecting information to conduct our business</span>
            <br />
            We collect, retain and use nonpublic personal information about you for the purpose of providing insurance,
            insurance-related services, and to respond to your specific requests. We may collect information about you
            from the following sources:
        </p>
        <ul>
            <li>
                Information you provide on applications or other forms, or information that you provide in response to
                our requests.
                <span style="font-style: italic">
                    (This includes identifying information, such as your name and address; financial information that
                    allows us to underwrite and service your policy; and security information.);
                </span>
            </li>
            <li>
                Information about your transactions with us or others
                <span style="font-style: italic">
                    (such as policies purchased and premium payment history); and
                </span>
            </li>
            <li>
                Information we receive from a consumer-reporting agency
                <span style="font-style: italic">
                    (such as your credit worthiness and credit history).
                </span>
            </li>
        </ul>
        <p></p>
        <br />
        <p>
            <span class="subheading">Disclosing information</span>
            <br />
            We do not disclose nonpublic personal information about our customers to third parties, other than those
            working on our behalf or as otherwise permitted or required by law. For example, we may disclose your
            financial information to a third party to assist with payment processing, and we may disclose your personal
            information to help up process a claim or underwrite your policy. We may also disclose your personal
            information to third parties to comply with a court order or other legal obligation.
        </p>
        <br />
        <p>
            <span class="subheading">Protecting the confidentiality and security of your information</span>
            <br />
            We restrict access to nonpublic personal information about you to those employees or others acting on our
            behalf who need to know that information to provide products or services to you. We maintain physical,
            electronic, and procedural safeguards that comply with applicable federal regulations to guard your
            nonpublic personal information.
            <br />
            <br />
            You have the right to obtain access to certain items of information we have collected about you. Further,
            you have the right to request correction of information if you feel it is inaccurate.
            <br />
            <br />
            Please contact us if you would like additional information about our policies and procedures to protect the
            privacy of your information. To request a copy of our privacy policy or to access your information, please
            contact our Privacy Officer at:
            <br />
            <br />
        </p>
        <div style="margin-left: 10px;">
            Toll-free 1-800-558-6411
            <br />
            Jewelers Mutual Insurance Company, SI
            <br />
            Attn: Privacy Officer
            <br />
            24 Jewelers Park Drive
            <br />
            P. O. Box 468
            <br />
            Neenah, WI USA 54957-0468
        </div>
        <p></p>
    </div>
</template>

<script>
export default {
    name: 'PrivacyNotice'
};
</script>

<style scoped lang="scss">
.subheading {
    font-weight: bold;
}
</style>
